var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('a-form',{attrs:{"form":_vm.form,"labelCol":_vm.labelCol,"wrapperCol":_vm.wrapperCol}},[_c('a-card',{attrs:{"title":"基本信息"}},[_c('a-row',[_c('a-col',{attrs:{"span":18}},[_c('a-row',[_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"hidden":"","label":"doctorId"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['doctorId',{ initialValue:'0' }]),expression:"['doctorId',{ initialValue:'0' }]"}],attrs:{"name":"doctorId"}})],1),_c('a-form-item',{attrs:{"label":"姓名"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                    'trueName',
                    {rules: [{ message: '请输入姓名' }]}
                  ]),expression:"[\n                    'trueName',\n                    {rules: [{ message: '请输入姓名' }]}\n                  ]"}],attrs:{"name":"trueName","placeholder":"请输入姓名"}})],1)],1),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"性别"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                    'sex'
                  ]),expression:"[\n                    'sex'\n                  ]"}],attrs:{"allowClear":"","placeholder":"请选择性别"}},[_c('a-select-option',{attrs:{"value":1}},[_vm._v(" 男 ")]),_c('a-select-option',{attrs:{"value":2}},[_vm._v(" 女 ")])],1)],1)],1)],1),_c('a-row',[_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"出生年月"}},[_c('a-date-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                    'birthday',
                    {rules: [{ type: 'object', message: '请输入出生年月' }]}
                  ]),expression:"[\n                    'birthday',\n                    {rules: [{ type: 'object', message: '请输入出生年月' }]}\n                  ]"}],attrs:{"allowClear":"","name":"birthday","placeholder":"请选择日期"}})],1)],1),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"电话号码"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                    'mobile',
                    {rules: [{ message: '请输入电话号码' }]}
                  ]),expression:"[\n                    'mobile',\n                    {rules: [{ message: '请输入电话号码' }]}\n                  ]"}],attrs:{"name":"mobile","placeholder":"多个电话号码请用英文逗号隔开"}})],1)],1)],1),_c('a-row',[_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"电子邮箱"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                    'email',
                    {rules: [{ message: '多个邮箱请用英文逗号隔开' }]}
                  ]),expression:"[\n                    'email',\n                    {rules: [{ message: '多个邮箱请用英文逗号隔开' }]}\n                  ]"}],attrs:{"name":"email","placeholder":"多个邮箱请用英文逗号隔开"}})],1)],1),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"排序"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                    'listorder',
                    {rules: [{ required: true, message: '请输入排序' }]}
                  ]),expression:"[\n                    'listorder',\n                    {rules: [{ required: true, message: '请输入排序' }]}\n                  ]"}],attrs:{"name":"path","placeholder":"请输入排序"}})],1)],1)],1)],1),_c('a-col',{style:({ minHeight: '180px' }),attrs:{"span":6}},[_c('a-form-item',[_c('AvatarUpload',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'headerurl'
              ]),expression:"[\n                'headerurl'\n              ]"}]})],1)],1)],1)],1),_c('a-card',{attrs:{"title":"专业相关信息"}},[_c('a-row',[_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"地区","prop":"regionId"}},[_c('a-cascader',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'regionId'
              ]),expression:"[\n                'regionId'\n              ]"}],attrs:{"name":"regionId","field-names":_vm.RegreplaceFields,"options":_vm.RegtreeData,"allow-clear":"","placeholder":"请选择"},on:{"change":_vm.handleRegionChange}})],1)],1),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"医院"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'nosoId'
              ]),expression:"[\n                'nosoId'\n              ]"}],attrs:{"allowClear":"","placeholder":"请选择医院","name":"nosoId"},on:{"change":_vm.handleDepartmentChange}},_vm._l((_vm.nosodata),function(item){return _c('a-select-option',{key:item.id,attrs:{"value":item.id}},[_vm._v(" "+_vm._s(item.name)+" ")])}),1)],1)],1)],1),_c('a-row',[_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"科室"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'nosoDepId'
              ]),expression:"[\n                'nosoDepId'\n              ]"}],attrs:{"allowClear":"","name":"nosoDepId","placeholder":"请选择科室"}},_vm._l((_vm.departmentData),function(item){return _c('a-select-option',{key:item.nosoDepId,attrs:{"value":item.nosoDepId}},[_vm._v(" "+_vm._s(item.name)+" ")])}),1)],1)],1),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"职称"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'positionId'
              ]),expression:"[\n                'positionId'\n              ]"}],attrs:{"allowClear":"","name":"positionId","placeholder":"请选择职称"}},_vm._l((_vm.positionData),function(item){return _c('a-select-option',{key:item.positionId,attrs:{"value":item.positionId}},[_vm._v(" "+_vm._s(item.name)+" ")])}),1)],1)],1)],1),_c('a-row',[_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"教学职称"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'lecturerId'
              ]),expression:"[\n                'lecturerId'\n              ]"}],attrs:{"allowClear":"","name":"lecturerId","placeholder":"请选择教学职称"}},_vm._l((_vm.lecturerData),function(item){return _c('a-select-option',{key:item.lecturerId,attrs:{"value":item.lecturerId}},[_vm._v(" "+_vm._s(item.name)+" ")])}),1)],1)],1),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"医院行政职务"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'administrativePost',
                {rules: [{ message: '请输入医院行政职务' }]}
              ]),expression:"[\n                'administrativePost',\n                {rules: [{ message: '请输入医院行政职务' }]}\n              ]"}],attrs:{"name":"administrativePost","placeholder":"请输入医院行政职务"}})],1)],1)],1),_c('a-row',[_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"社会任职"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'societyName',
                {rules: [{message: '请输入社会任职' }]}
              ]),expression:"[\n                'societyName',\n                {rules: [{message: '请输入社会任职' }]}\n              ]"}],attrs:{"name":"societyName","placeholder":"请输入社会任职"}})],1)],1),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"擅长"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'specialty',
                {rules: [{message: '请输入擅长' }]}
              ]),expression:"[\n                'specialty',\n                {rules: [{message: '请输入擅长' }]}\n              ]"}],attrs:{"name":"specialty","placeholder":"请输入擅长"}})],1)],1)],1),_c('a-row',[_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"备注"}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'remarks',
                {rules: [{message: '请输入备注' }]}
              ]),expression:"[\n                'remarks',\n                {rules: [{message: '请输入备注' }]}\n              ]"}],attrs:{"name":"remarks","placeholder":"请输入备注"}})],1)],1),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"required":false,"label":"状态"}},[_c('a-radio-group',{directives:[{name:"decorator",rawName:"v-decorator",value:(['status', { initialValue: 1 }]),expression:"['status', { initialValue: 1 }]"}],attrs:{"name":"status"}},[_c('a-radio',{attrs:{"value":1}},[_vm._v("启用")]),_c('a-radio',{attrs:{"value":2}},[_vm._v("禁用")])],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }