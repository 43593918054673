<template>
  <div>
    <a-form
      :form="form"
      :labelCol="labelCol"
      :wrapperCol="wrapperCol">
      <a-card title="基本信息">
        <a-row>
          <a-col :span="18">
            <a-row>
              <a-col :span="12">
                <a-form-item
                  hidden
                  label="doctorId"
                >
                  <a-input
                    v-decorator="['doctorId',{ initialValue:'0' }]"
                    name="doctorId"></a-input>
                </a-form-item>

                <a-form-item
                  label="姓名"
                >
                  <a-input
                    v-decorator="[
                      'trueName',
                      {rules: [{ message: '请输入姓名' }]}
                    ]"
                    name="trueName"
                    placeholder="请输入姓名"/>
                </a-form-item>
              </a-col>
              <a-col :span="12">
                <a-form-item
                  label="性别"
                >
                  <a-select
                    allowClear
                    placeholder="请选择性别"
                    v-decorator="[
                      'sex'
                    ]">
                    <a-select-option :value="1">
                      男
                    </a-select-option>
                    <a-select-option :value="2">
                      女
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
            </a-row>
            <a-row>
              <a-col :span="12">
                <a-form-item
                  label="出生年月"
                >
                  <a-date-picker
                    allowClear
                    v-decorator="[
                      'birthday',
                      {rules: [{ type: 'object', message: '请输入出生年月' }]}
                    ]"
                    name="birthday"
                    placeholder="请选择日期">
                  </a-date-picker>
                </a-form-item>
              </a-col>
              <a-col :span="12">
                <a-form-item
                  label="电话号码"
                >
                  <a-input
                    v-decorator="[
                      'mobile',
                      {rules: [{ message: '请输入电话号码' }]}
                    ]"
                    name="mobile"
                    placeholder="多个电话号码请用英文逗号隔开">
                  </a-input>
                </a-form-item>
              </a-col>
            </a-row>
            <a-row>
              <a-col :span="12">
                <a-form-item
                  label="电子邮箱"
                >
                  <a-input
                    v-decorator="[
                      'email',
                      {rules: [{ message: '多个邮箱请用英文逗号隔开' }]}
                    ]"
                    name="email"
                    placeholder="多个邮箱请用英文逗号隔开">
                  </a-input>
                </a-form-item>
              </a-col>
              <a-col :span="12">
                <a-form-item
                  label="排序"
                >
                  <a-input
                    v-decorator="[
                      'listorder',
                      {rules: [{ required: true, message: '请输入排序' }]}
                    ]"
                    name="path"
                    placeholder="请输入排序"/>
                </a-form-item>
              </a-col>
            </a-row>
          </a-col>
          <a-col :span="6" :style="{ minHeight: '180px' }">
            <a-form-item>
              <AvatarUpload
                v-decorator="[
                  'headerurl'
                ]"/>
            </a-form-item>
          </a-col>
        </a-row>
      </a-card>
      <a-card title="专业相关信息">
        <a-row>
          <a-col :span="12">
            <a-form-item label="地区" prop="regionId">
              <a-cascader
                v-decorator="[
                  'regionId'
                ]"
                name="regionId"
                :field-names="RegreplaceFields"
                :options="RegtreeData"
                allow-clear
                placeholder="请选择"
                @change="handleRegionChange"
              />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item
              label="医院"
            >
              <a-select
                allowClear
                v-decorator="[
                  'nosoId'
                ]"
                placeholder="请选择医院"
                name="nosoId"
                @change="handleDepartmentChange"
              >
                <a-select-option v-for="item in nosodata" :key="item.id" :value="item.id">
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="12">
            <a-form-item
              label="科室"
            >
              <a-select
                allowClear
                v-decorator="[
                  'nosoDepId'
                ]"
                name="nosoDepId"
                placeholder="请选择科室"
              >
                <a-select-option v-for="item in departmentData" :key="item.nosoDepId" :value="item.nosoDepId">
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item
              label="职称"
            >
              <a-select
                allowClear
                v-decorator="[
                  'positionId'
                ]"
                name="positionId"
                placeholder="请选择职称"
              >
                <a-select-option v-for="item in positionData" :key="item.positionId" :value="item.positionId">
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-item>

          </a-col>
        </a-row>
        <a-row>
          <a-col :span="12">
            <a-form-item
              label="教学职称"
            >
              <a-select
                allowClear
                v-decorator="[
                  'lecturerId'
                ]"
                name="lecturerId"
                placeholder="请选择教学职称"
              >
                <a-select-option v-for="item in lecturerData" :key="item.lecturerId" :value="item.lecturerId">
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>

          <a-col :span="12">
            <a-form-item
              label="医院行政职务"
            >
              <a-input
                v-decorator="[
                  'administrativePost',
                  {rules: [{ message: '请输入医院行政职务' }]}
                ]"
                name="administrativePost"
                placeholder="请输入医院行政职务"/>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="12">
            <a-form-item
              label="社会任职"
            >
              <a-input
                v-decorator="[
                  'societyName',
                  {rules: [{message: '请输入社会任职' }]}
                ]"
                name="societyName"
                placeholder="请输入社会任职"/>
            </a-form-item>
          </a-col>

          <a-col :span="12">
            <a-form-item
              label="擅长"
            >
              <a-input
                v-decorator="[
                  'specialty',
                  {rules: [{message: '请输入擅长' }]}
                ]"
                name="specialty"
                placeholder="请输入擅长"/>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="12">
            <a-form-item
              label="备注"
            >
              <a-textarea
                v-decorator="[
                  'remarks',
                  {rules: [{message: '请输入备注' }]}
                ]"
                name="remarks"
                placeholder="请输入备注"/>
            </a-form-item>
          </a-col>

          <a-col :span="12">
            <a-form-item
              :required="false"
              label="状态"
            >
              <a-radio-group v-decorator="['status', { initialValue: 1 }]" name="status">
                <a-radio :value="1">启用</a-radio>
                <a-radio :value="2">禁用</a-radio>
              </a-radio-group>
            </a-form-item>
          </a-col>
        </a-row>
      </a-card>
    </a-form>
  </div>
</template>

<script>
import AvatarUpload from '@/components/upload'
import { allList as nosodochiumList } from '@/api/nosodochium'
import { cityList as RegAllList } from '@/api/region'
import { allList as departmentAllList } from '@/api/nosodochiumdep'
import { allList as lecturerAllList } from '@/api/lecturer'
import { allList as positionAllList } from '@/api/position'

import { Cascader as ACascader } from 'ant-design-vue'

export default {
  components: {
    AvatarUpload,
    ACascader
  },
  data () {
    return {
      positionData: [],
      lecturerData: [],
      departmentData: [],
      RegtreeData: [],
      nosodata: [],
      RegreplaceFields: { label: 'name', value: 'regionId', children: 'children' },
      labelCol: { span: 6 },
      wrapperCol: { span: 17 },
      form: this.$form.createForm(this)
    }
  },
  mounted () {
    this.getRegAllList()
    this.getLecturerAllList()
    this.getPositionAllList()
  },
  methods: {
    getNosodochiumList (params) {
      nosodochiumList(params).then(res => {
        this.nosodata = res.result
        this.$forceUpdate()
      })
    },
    getRegAllList () {
      RegAllList().then(res => {
        this.RegtreeData = res.result
        this.$forceUpdate()
      })
    },
    getDepartmentAllList (params) {
      departmentAllList(params).then(res => {
        this.departmentData = res.result
        this.$forceUpdate()
      })
    },
    getLecturerAllList () {
      lecturerAllList().then(res => {
        this.lecturerData = res.result
        this.$forceUpdate()
      })
    },
    getPositionAllList () {
      positionAllList().then(res => {
        this.positionData = res.result
        this.$forceUpdate()
      })
    },
    handleRegionChange (value) {
      this.form.setFieldsValue({
        nosoId: undefined,
        nosoDepId: undefined
      })
      this.getNosodochiumList({ 'regionIds': value.join(',') })
    },
    handleDepartmentChange (value, opt) {
      this.form.setFieldsValue({
        nosoDepId: undefined
      })
      this.getDepartmentAllList({ 'nosoId': value })
    }
  }
}
</script>
